import React, { useState, useContext, useEffect } from "react"
import Accordion from "react-bootstrap/Accordion"
import {
  ArrowRight,
  Check,
  Search,
  Plus,
  Minus,
  ChevronDown,
  ChevronRight,
  ArrowDown,
} from "react-feather"
import Image from "../../components/Image"
import LeadGeneratorLoadable from "../../components/generator/LeadGeneratorLoadable"
// import SectionMaklerCheckliste from "../components/SectionMaklerCheckliste"
import HelmetHead from "../../components/HelmetHead"
import ClipboardCheck from "../../svgs/icons/duotone-icons/Communication/Clipboard-check.svg"
import TimeSchedule from "../../svgs/icons/duotone-icons/Code/Time-schedule.svg"
import ActiveCall from "../../svgs/icons/duotone-icons/Communication/Active-call.svg"
import Group from "../../svgs/icons/duotone-icons/Communication/Group.svg"
import Marker1 from "../../svgs/icons/duotone-icons/Map/marker-1.svg"

import imgLeft from "../../images/custom/immorating_cta_links.png"
import imgRight from "../../images/custom/immorating_cta_rechts.png"

import Curve1 from "../../svgs/shapes/curves/curve-1.svg"
import Curve3 from "../../svgs/shapes/curves/curve-3.svg"
import { Link } from "gatsby"
const CTA = Loadable(() => import("../../components/CTA"))
import BackgroundSection from "../../components/BackgroundSection"
import AccordionHeaderCustomScroll from "../../components/AccordionHeaderCustomScroll"
import smoothScroll from "../../components/smoothScroll"

import LayoutContext from "../../components/LayoutContext"
import Loadable from "@loadable/component"

const MaklerFinden = ({ location }) => {
  const [accordion, setAccordion] = useState(
    (location && location.state && location.state.accordion) || "teure-fehler"
  )

  // define if lead-generator is used in page!
  const canonicalPathname = `/de/makler-finden/`
  const fixedPathname = `/de/makler-finden/`

  const [layout, setLayout] = useContext(LayoutContext)

  useEffect(() => {
    setLayout(state => ({
      ...state,
      header: {},
    }))
  }, [])

  return (
    <>
      <HelmetHead
        title="Makler finden: Leitfaden und Empfehlungen für Ihren Immobilienmakler"
        description="Worauf kommt es an, wenn Sie einen seriösen Immobilienmakler finden möchten? Wichtige Tipps für die Maklersuche: Über häufige Fehler, Provisionen und Empfehlungen."
        keywords="Makler, Immobilienmakler, Makler Empfehlung, Makler finden, Makler suchen"
        links={[
          {
            rel: "canonical",
            href: `${process.env.GATSBY_SITE_URL}${canonicalPathname}`,
          },
        ]}
      />
      <BackgroundSection src="AdobeStock_309202568_2.jpg" className="bg-cover">
        <div className="container">
          <div
            className="row justify-content-center text-center align-items-end pb-10"
            style={{ minHeight: "90vh" }}
          >
            <div className="col-12">
              <h1 className="display-3 mb-5 fw-bold text-white pt-15 text-shadow-custom">
                Makler finden
              </h1>
              <h2 className="display-4 text-white text-center fw-bold text-shadow-custom">
                Leitfaden und Empfehlungen für Ihren Immobilienmakler
              </h2>
            </div>
          </div>
        </div>
      </BackgroundSection>

      <div className="position-relative">
        <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
          <Curve1 />
        </div>
      </div>

      <section className="py-7 py-md-9 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="scroll-beacon" id="makler-finden-intro" />
              <a
                href="#makler-finden-intro"
                className="btn btn-white btn-rounded-circle shadow mt-n11 mt-md-n13"
                onClick={smoothScroll}
              >
                <ArrowDown size={20} />
              </a>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-12">
              <h1 className="fw-bold text-primary mb-0">
                Besser verkaufen mit einem versierten Makler
              </h1>
              <p className="fs-lg text-muted">
                Ladenhüter verlieren schnell an Wert! Wäre es nicht viel besser,
                wenn Sie Ihre Immobilie zügig und zum besten Preis veräußern
                würden, weil Sie
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 mb-7 mb-md-0">
              <ul className="fs-lg text-muted ps-4">
                <li>
                  den realistischen Wert Ihrer Immobilie kennen, den Sie
                  erzielen können?
                </li>
                <li>
                  das Know-How eines echten und seriösen Verkaufs-Profis für
                  sich nutzen?
                </li>
                <li>
                  von Erfahrungen tausender anderer Verkäufer profitieren?
                </li>
              </ul>

              <h2 className="fw-bold mb-0">
                Leitfaden und Empfehlungen für Ihren Immobilienmakler
              </h2>

              <p className="fs-lg text-muted">
                Ein altes Möbel- oder Kleidungsstück zu verkaufen, ist schnell
                erledigt. Stehen Sie als Eigentümer jedoch vor der Aufgabe, Ihre
                Immobilie zu verkaufen, so kommen einige Fragen, Überlegungen
                und grundlegende Entscheidungen auf Sie zu:
                <br />
                <b>
                  Makler, ja oder nein? Wenn ja, worauf kommt es an? Wie findet
                  man einen wirklich guten Makler?
                </b>
                <br />
                Um Ihnen diesen Prozess zu erleichtern, haben wir einen
                umfassenden Leitfaden für Sie erstellt:
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-6 offset-lg-1">
              <h3 className="fw-bold text-center">Makler finden – Leitfaden</h3>

              <div className="card card-border border-success shadow-lg mb-5">
                <div className="card-body">
                  <div className="list-group list-group-flush">
                    <div className="list-group-item d-flex align-items-center">
                      <div className="me-auto">
                        <a
                          href="#warum-makler-7-gruende"
                          className="fw-bold mb-1"
                          onClick={smoothScroll}
                        >
                          Warum ein Makler sinnvoll ist: 7 Gründe
                        </a>
                      </div>
                      <div className="badge badge-rounded-circle bg-primary-desat-soft ms-4">
                        <ArrowRight size={15} />
                      </div>
                    </div>
                    <div className="list-group-item d-flex align-items-center">
                      <div className="me-auto">
                        <a
                          href="#richtige-makler"
                          className="fw-bold mb-1"
                          onClick={smoothScroll}
                        >
                          Darauf sollten Sie bei einem Makler achten
                        </a>
                      </div>
                      <div className="badge badge-rounded-circle bg-primary-desat-soft ms-4">
                        <ArrowRight size={15} />
                      </div>
                    </div>
                    <div className="list-group-item d-flex align-items-center">
                      <div className="me-auto">
                        <a
                          href="#tipps-maklersuche"
                          className="fw-bold mb-1"
                          onClick={smoothScroll}
                        >
                          3 wichtige Tipps für Ihre Maklersuche
                        </a>
                      </div>
                      <div className="badge badge-rounded-circle bg-primary-desat-soft ms-4">
                        <ArrowRight size={15} />
                      </div>
                    </div>
                    <div className="list-group-item d-flex align-items-center">
                      <div className="me-auto">
                        <a
                          href="#makler-finden-checkliste"
                          className="fw-bold mb-1"
                          onClick={smoothScroll}
                        >
                          Makler finden – Schritt für Schritt Checkliste
                        </a>
                      </div>
                      <div className="badge badge-rounded-circle bg-primary-desat-soft ms-4">
                        <ArrowRight size={15} />
                      </div>
                    </div>
                    <div className="list-group-item d-flex align-items-center">
                      <div className="me-auto">
                        <a
                          href="#makler-finden"
                          onClick={smoothScroll}
                          className="fw-bold mb-1"
                        >
                          Finden Sie jetzt den richtigen Makler
                        </a>
                      </div>
                      <div className="badge badge-rounded-circle bg-primary-desat-soft ms-4">
                        <ArrowRight size={15} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*<BackgroundSection*/}
      {/*  src="ImmobilieGartenSW.jpg"*/}
      {/*  className="py-8 py-md-11 border-bottom"*/}
      {/*  overlayColor="linear-gradient(rgba(11, 33, 76, .95), rgba(11, 33, 76, .95))"*/}
      {/*>*/}
      {/*  <div className="container">*/}
      {/*    <div className="scroll-beacon" id="warum-makler-7-gruende" />*/}
      {/*    <h2 className="fw-bold text-white text-center mb-7 mb-md-9">*/}
      {/*      Folgende Bereiche deckt ein Makler u.a. für Sie ab:*/}
      {/*    </h2>*/}

      {/*    <div className="row">*/}
      {/*      <div className="col-12 col-md-6">*/}
      {/*        <div className="d-flex">*/}
      {/*          <div className="badge badge-rounded-circle bg-success mt-1 me-4">*/}
      {/*            <Check size={15} />*/}
      {/*          </div>*/}
      {/*          <p className="text-gray-300">*/}
      {/*            Erstellung eines aussagekräftigen und attraktiven{" "}*/}
      {/*            <a*/}
      {/*              href="https://de.wikipedia.org/wiki/Expos%C3%A9_(Immobilien)"*/}
      {/*              target="_blank"*/}
      {/*              className="text-gray-300"*/}
      {/*            >*/}
      {/*              Exposés*/}
      {/*            </a>*/}
      {/*          </p>*/}
      {/*        </div>*/}

      {/*        <div className="d-flex">*/}
      {/*          <div className="badge badge-rounded-circle bg-success mt-1 me-4">*/}
      {/*            <Check size={15} />*/}
      {/*          </div>*/}
      {/*          <p className="text-gray-300">Wertermittlung Ihrer Immobilie</p>*/}
      {/*        </div>*/}

      {/*        <div className="d-flex">*/}
      {/*          <div className="badge badge-rounded-circle bg-success mt-1 me-4">*/}
      {/*            <Check size={15} />*/}
      {/*          </div>*/}
      {/*          <p className="text-gray-300">*/}
      {/*            Abklärung von Bau- und Vertragsrecht*/}
      {/*          </p>*/}
      {/*        </div>*/}

      {/*        <div className="d-flex">*/}
      {/*          <div className="badge badge-rounded-circle bg-success mt-1 me-4">*/}
      {/*            <Check size={15} />*/}
      {/*          </div>*/}
      {/*          <p className="text-gray-300">*/}
      {/*            Erstellung und professionelle Bearbeitung hochwertiger Bilder*/}
      {/*            Ihrer Immobilie*/}
      {/*          </p>*/}
      {/*        </div>*/}

      {/*        <div className="d-flex">*/}
      {/*          <div className="badge badge-rounded-circle bg-success mt-1 me-4">*/}
      {/*            <Check size={15} />*/}
      {/*          </div>*/}
      {/*          <p className="text-gray-300">*/}
      {/*            Besichtigungsmanagement (Sichtung der Bewerbungen,*/}
      {/*            Terminvereinbarung, Durchführung, Nachbereitung)*/}
      {/*          </p>*/}
      {/*        </div>*/}

      {/*        <div className="d-flex">*/}
      {/*          <div className="badge badge-rounded-circle bg-success mt-1 me-4">*/}
      {/*            <Check size={15} />*/}
      {/*          </div>*/}
      {/*          <p className="text-gray-300">*/}
      {/*            Entwicklung einer Marketingstrategie (mit Fokus auf eine*/}
      {/*            spezifische Zielgruppe)*/}
      {/*          </p>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="col-12 col-md-6">*/}
      {/*        <div className="d-flex">*/}
      {/*          <div className="badge badge-rounded-circle bg-success mt-1 me-4">*/}
      {/*            <Check size={15} />*/}
      {/*          </div>*/}
      {/*          <p className="text-gray-300">*/}
      {/*            Bonitätsprüfung und Selektion der Bewerber*/}
      {/*          </p>*/}
      {/*        </div>*/}

      {/*        <div className="d-flex">*/}
      {/*          <div className="badge badge-rounded-circle bg-success mt-1 me-4">*/}
      {/*            <Check size={15} />*/}
      {/*          </div>*/}
      {/*          <p className="text-gray-300">*/}
      {/*            Professionelle Verkaufsverhandlungen*/}
      {/*          </p>*/}
      {/*        </div>*/}

      {/*        <div className="d-flex">*/}
      {/*          <div className="badge badge-rounded-circle bg-success mt-1 me-4">*/}
      {/*            <Check size={15} />*/}
      {/*          </div>*/}
      {/*          <p className="text-gray-300">*/}
      {/*            Ordnungsgemäße Übergabe der Immobilie*/}
      {/*          </p>*/}
      {/*        </div>*/}

      {/*        <div className="d-flex">*/}
      {/*          <div className="badge badge-rounded-circle bg-success mt-1 me-4">*/}
      {/*            <Check size={15} />*/}
      {/*          </div>*/}
      {/*          <p className="text-gray-300">Nachbetreuung des Verkaufs</p>*/}
      {/*        </div>*/}

      {/*        <div className="d-flex">*/}
      {/*          <div className="badge badge-rounded-circle bg-success mt-1 me-4">*/}
      {/*            <Check size={15} />*/}
      {/*          </div>*/}
      {/*          <p className="text-gray-300">*/}
      {/*            Beschaffung und Zusammenstellung aller nötigen Unterlagen*/}
      {/*            (z.B. Energieausweis, Grundbuchauszüge, Grundrisse,*/}
      {/*            Grundsteuerbescheid …)*/}
      {/*          </p>*/}
      {/*        </div>*/}

      {/*        <div className="d-flex">*/}
      {/*          <div className="badge badge-rounded-circle bg-success mt-1 me-4">*/}
      {/*            <Check size={15} />*/}
      {/*          </div>*/}
      {/*          <p className="text-gray-300">*/}
      {/*            Aufsetzung eines Notarvertrags und Kommunikation mit dem*/}
      {/*            Notariat*/}
      {/*          </p>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</BackgroundSection>*/}

      <section
        className="py-8 py-md-11 bg-gray-200 border-bottom bg-between"
        style={{
          backgroundImage: `url(${imgLeft}), url(${imgRight})`,
          backgroundPosition: "left bottom, right bottom",
          backgroundSize: "auto 50%",
        }}
      >
        <div className="container">
          <h2 className="fw-bold text-center">
            Warum ein Makler sinnvoll ist: <br className="d-block d-sm-none" />7
            Gründe
          </h2>

          <Accordion
            defaultActiveKey={accordion}
            className="card shadow-light-lg mt-7 mt-md-9 mb-5 mb-md-6"
          >
            <Accordion.Item eventKey="teure-fehler">
              <AccordionHeaderCustomScroll anchor="teure-fehler">
                <span className="badge badge-lg badge-rounded-circle bg-primary me-2">
                  <span>1</span>
                </span>
                Teure Fehler vermeiden
              </AccordionHeaderCustomScroll>
              <Accordion.Body>
                <p className="fs-lg text-muted mb-7 mb-md-9">
                  Das Erste woran viele Menschen denken, wenn sie
                  Immobilienmakler hören:{" "}
                  <b>„Ich muss bestimmt eine hohe Provision zahlen!“</b>. Und
                  ja, ein Makler muss bezahlt werden. Diese Rechnung ist jedoch
                  noch nicht zu Ende gedacht. Denn zum einen wissen Sie jetzt,
                  welche teilweise komplexen Aufgaben Ihnen ein Makler von den
                  Schultern nimmt. Zum anderen bewahrt er Sie vor evtl. sehr
                  teuren Fehlern. Zwei häufig vorkommenden Szenarien sollten Sie
                  sich als Eigentümer bewusst sein:
                </p>

                <div className="row align-items-stretch justify-content-center">
                  <div className="col-11 col-md-4 p-md-0">
                    <h4
                      data-sal="slide-up"
                      data-sal-delay="200"
                      data-sal-duration="700"
                    >
                      Sie <b>ÜBERschätzen</b>
                      <br />
                      den Wert Ihrer Immobilie:
                    </h4>
                    <p className="text-muted">
                      Keine Seltenheit, denn natürlich stellt die Immobilie auch
                      einen emotionalen Wert für Sie dar. Problematisch ist,
                      dass zu hohe Preise potenzielle Käufer abschrecken können,
                      was sich auch langfristig auf den Verkauf Ihrer Immobilie
                      auswirken wird.
                    </p>
                  </div>
                  <div
                    className="col-12 col-md-1 mt-n4 d-none d-md-block"
                    style={{ borderRight: "1px solid #009cdf" }}
                  />
                  <div className="col-11 col-md-4 p-md-0 offset-md-1">
                    <h4
                      data-sal="slide-down"
                      data-sal-delay="700"
                      data-sal-duration="700"
                    >
                      Sie <b>UNTERschätzen</b>
                      <br />
                      den Wert Ihrer Immobilie:
                    </h4>

                    <p className="text-muted">
                      Nicht selten liegen Eigentümer damit bis zu 40% unter dem
                      eigentlichen Verkaufspreis. Bei einem Immobilienwert von
                      300.000 EUR wären das ganze 120.000 EUR, die Ihnen
                      entgehen würden. Eine Summe, die um ein Vielfaches über
                      der Maklerprovision liegt.
                    </p>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="aufwand-und-nerven">
              <AccordionHeaderCustomScroll anchor="aufwand-und-nerven">
                <span className="badge badge-lg badge-rounded-circle bg-primary me-2">
                  <span>2</span>
                </span>
                Aufwand und Nerven sparen
              </AccordionHeaderCustomScroll>
              <Accordion.Body>
                <p className="text-muted">
                  Der Verkauf einer Immobilie erfordert teilweise sehr
                  spezifische Kompetenzen, die Sie sich als Laie in mühsamer
                  Recherche und mit hohem Zeitaufwand erarbeiten müssen. Ein
                  Immobilienmakler verfügt über das nötige Fachwissen und
                  Erfahrung in der Branche und erfüllt sämtliche Aufgaben sehr
                  viel zeiteffizienter für Sie.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="schneller-verkauf">
              <AccordionHeaderCustomScroll anchor="schneller-verkauf">
                <span className="badge badge-lg badge-rounded-circle bg-primary me-2">
                  <span>3</span>
                </span>
                Schnellerer Verkauf der Immobilie
              </AccordionHeaderCustomScroll>
              <Accordion.Body>
                <p className="text-muted">
                  Ein Haus über mehrere Monate leer stehen zu haben, ist nicht
                  nur <b>„verschenktes Geld“</b>, sondern auch nicht besonders
                  nachhaltig. Doch wenn Sie als Eigentümer, den Verkauf Ihrer
                  Immobilie selbst in die Hand nehmen, ist es sehr
                  wahrscheinlich, dass dieser Fall eintreten wird. Abgesehen von
                  den zeitintensiven Besichtigungen, werden vor allem der
                  bürokratische Aufwand und die notwendigen rechtlichen Schritte
                  nicht selten unterschätzt. Ein Makler wird Ihnen den Prozess
                  erleichtern und in kürzester Zeit vertrauenswürdige Käufer
                  finden.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="marktkenntnis">
              <AccordionHeaderCustomScroll anchor="marktkenntnis">
                <span className="badge badge-lg badge-rounded-circle bg-primary me-2">
                  <span>4</span>
                </span>
                Marktkenntnis und Kundenstamm nutzen
              </AccordionHeaderCustomScroll>
              <Accordion.Body>
                <p className="text-muted">
                  Nutzen auch Sie den Vorteil, dass ein erfahrener
                  Immobilienmakler den Markt in Ihrer Region und die dortige
                  Nachfrage sehr gut kennt. Er ist stets informiert über
                  aktuelle Trends und Objekte, die besonders gefragt sind. Den
                  Kaufpreis kann er mit diesem Wissen sehr realistisch
                  einschätzen. Zudem verfügt jeder Makler über einen breiten
                  Kundenstamm verschiedener Interessenten. Ein weiterer Faktor,
                  der den <b>Verkaufsprozess deutlich beschleunigen</b> kann.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="rechtliche-sicherheit">
              <AccordionHeaderCustomScroll anchor="rechtliche-sicherheit">
                <span className="badge badge-lg badge-rounded-circle bg-primary me-2">
                  <span>5</span>
                </span>
                Rechtliche Sicherheit gewährleisten
              </AccordionHeaderCustomScroll>
              <Accordion.Body>
                <p className="text-muted">
                  Als Verkäufer einer Immobilie sollten Sie sich mit der
                  aktuellen Rechtslage und den zugehörigen Unterlagen gut
                  auskennen. Denn ein fehlerhafter Vertragsschluss wird Ihnen in
                  aller Regel <b>langfristig Probleme</b> bescheren. Makler
                  wissen es, sichere Kaufverträge zu erstellen und kennen
                  sämtliche Möglichkeiten, etwaige Kosten auf den Käufer
                  umzulegen. Auch bei der Zusammenstellung aller weiteren
                  Unterlagen, von{" "}
                  <a
                    href="https://de.wikipedia.org/wiki/Energieausweis"
                    target="_blank"
                    className="text-muted"
                  >
                    Energieausweis
                  </a>{" "}
                  bis zum{" "}
                  <a
                    href="https://de.wikipedia.org/wiki/Wohnungs%C3%BCbergabeprotokoll"
                    target="_blank"
                    className="text-muted"
                  >
                    Übergabeprotokoll
                  </a>
                  , wird Sie ein Makler professionell unterstützen.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="verkaufen-gelernt">
              <AccordionHeaderCustomScroll anchor="verkaufen-gelernt">
                <span className="badge badge-lg badge-rounded-circle bg-primary me-2">
                  <span>6</span>
                </span>
                Verkaufen will gelernt sein
              </AccordionHeaderCustomScroll>
              <Accordion.Body>
                <p className="text-muted">
                  Wie schwer kann es schon sein, ein Haus zu verkaufen!? Leider
                  sehr viel schwieriger als sich das der ein oder andere
                  vorstellt. Verkaufen will gelernt sein und bedarf außerdem
                  einer Menge Erfahrungsschatz und Geschick. Vor allem wenn es
                  wie bei Immobilien, um große Summen geht! Ein Makler verfügt
                  über das nötige{" "}
                  <b>Fachwissen und die kommunikativen Fähigkeiten</b>, die es
                  braucht, um erfolgreiche Verkaufsgespräche zu führen.
                  Vertrauen Sie hier dem Profi.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="bisher-kostenlos">
              <AccordionHeaderCustomScroll anchor="bisher-kostenlos">
                <span className="badge badge-lg badge-rounded-circle bg-primary me-2">
                  <span>7</span>
                </span>
                Bisher kostenlos für Verkäufer
              </AccordionHeaderCustomScroll>
              <Accordion.Body>
                <p className="text-muted">
                  Wer für den Verkauf einer Immobilie einen Makler beauftragt,
                  spart sich dadurch oft viel Zeit und Aufwand. Je nach Region
                  beträgt die Maklercourtage zwischen drei und sieben Prozent
                  des Kaufpreises plus Mehrwertsteuer.{" "}
                  <b>Üblicherweise trägt der Käufer die Maklercourtage.</b> Seit
                  Dezember 2020 ist die Aufteilung bei selbstgenutzten
                  Immobilien gesetzlich gedeckelt: Wer den Immobilienmakler
                  beauftragt, muss mindestens die Hälfte der Gesamtprovision
                  zahlen.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <div className="row justify-content-center">
            <div
              className="col-12 col-md-7 col-lg-6"
              data-sal="fade"
              data-sal-delay="200"
              data-sal-duration="700"
            >
              <a
                href="#makler-finden"
                onClick={smoothScroll}
                className="btn btn-block btn-primary d-flex align-items-center lift"
              >
                Finden Sie jetzt den richtigen Makler{" "}
                <ArrowRight size={15} className="ms-auto" />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="py-8 py-md-11 border-bottom">
        <div className="container">
          <div className="scroll-beacon" id="richtige-makler" />
          <div className="row justify-content-center mb-5">
            <div className="col-12 col-md-10 col-lg-8 text-center">
              <span className="badge rounded-pill bg-success-soft mb-3">
                <span className="h6 text-uppercase">Der richtige Makler</span>
              </span>
              <h2 className="fw-bold">
                Darauf sollten Sie bei einem Makler achten
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4 d-md-none d-lg-block">
              <div
                className="icon mb-4 text-center text-primary"
                data-sal="slide-down"
                data-sal-delay="200"
                data-sal-duration="700"
              >
                <TimeSchedule />
              </div>
              <h3 className="fw-bold text-center">Gute Erreichbarkeit</h3>
              <p className="text-muted">
                Sie möchten Ihre Immobilie zeitnah verkaufen.{" "}
                <b>Eine unkomplizierte und schnelle Kommunikation</b> mit dem
                Makler ist dafür die absolute Voraussetzung. Da Makler
                telefonisch tagsüber (aufgrund von Besichtigungs­terminen) nur
                sehr schlecht erreichbar sind, sollten Sie darauf achten, dass
                Sie auf schriftlichem Weg innerhalb von 24 Stunden immer eine
                Antwort bekommen.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div
                className="icon mb-4 text-center text-primary"
                data-sal="slide-down"
                data-sal-delay="200"
                data-sal-duration="700"
              >
                <Group />
              </div>
              <h3 className="fw-bold text-center">Kundenrezensionen</h3>
              <p className="text-muted">
                Bei eigentlich jeder Dienstleistung sind Sie gut beraten, wenn
                Sie sich aussagekräftige Kundenbewertungen durchlesen. Auch
                hierfür können Sie <b>Online-Immobilienportale</b> von Nutzen
                sein. Fragen Sie jedoch auch in Ihrer Nachbarschaft oder bei
                Bekannten nach möglichen Empfehlungen.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div
                className="icon mb-4 text-center text-primary"
                data-sal="slide-down"
                data-sal-delay="200"
                data-sal-duration="700"
              >
                <Marker1 />
              </div>
              <h3 className="fw-bold text-center">Regionale Aktivität</h3>
              <p className="text-muted">
                Entscheiden Sie sich am besten für einen Makler, der in Ihrer
                Region besonders aktiv ist. Denn die{" "}
                <b>regionale Marktkenntnis</b> ist eine der wichtigsten
                Eigenschaften für eine schnelle Vermittlung. Gehen Sie dafür auf
                Online-Immobilienplattformen und verschaffen sich ein Bild
                davon, wer in Ihrer Region besonders viele Objekte inseriert.
              </p>
            </div>

            <div className="col-12 my-5">
              <hr />
            </div>

            <div className="col-12 col-md-6">
              <div
                className="icon mb-4 text-center text-primary-desat"
                data-sal="slide-down"
                data-sal-delay="200"
                data-sal-duration="700"
              >
                <ClipboardCheck />
              </div>
              <h3 className="fw-bold text-center">Visitenkarte und Exposés</h3>
              <p className="text-muted">
                Der <b>Internet-Auftritt</b> inklusive der Gestaltung der
                Exposés, kann Ihnen außerdem viel Aufschluss über die
                Arbeitsweise eines Maklers geben. Schauen Sie sich die
                regionalen Inserate an: Welche Exposés gefallen Ihnen besonders
                gut? Wie wirkt die Visitenkarte des Maklers auf Sie? Achten Sie
                dabei insbesondere auf die folgenden Merkmale:
              </p>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                      <Check size={15} />
                    </div>
                    <p className="text-success">
                      Übersichtlichkeit und Professionalität
                    </p>
                  </div>
                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                      <Check size={15} />
                    </div>
                    <p className="text-success">Güte des eigenen Marketings</p>
                  </div>
                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                      <Check size={15} />
                    </div>
                    <p className="text-success">
                      Verfügbarkeit einer eigenen Website
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-6 mb-6 mb-md-0">
                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                      <Check size={15} />
                    </div>
                    <p className="text-success">Angabe einer Büro-Adresse</p>
                  </div>
                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                      <Check size={15} />
                    </div>
                    <p className="text-success">
                      Ansprechend gestaltete Exposés
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div
                className="icon mb-4 text-center text-primary-desat"
                data-sal="slide-down"
                data-sal-delay="200"
                data-sal-duration="700"
              >
                <ActiveCall />
              </div>
              <h3 className="fw-bold text-center">
                Professionelle Kommunikation
              </h3>
              <p className="text-muted">
                Eine professionelle Kommunikation zeichnet sich außerdem durch
                eine hohe Effizienz aus. Anstatt unnötig viel hin- und
                herzuschreiben, sollte ein guter Makler in der Lage sein, direkt{" "}
                <b>die „richtigen“ Fragen</b> zu Ihrer Immobilie zu stellen.
                Diese Fragen betreffen u.a.:
              </p>
              <br className="d-none d-md-inline" />
              <br className="d-none d-md-inline d-xl-none" />
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                      <Check size={15} />
                    </div>
                    <p className="text-success">Grundriss- und Lagepläne</p>
                  </div>
                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                      <Check size={15} />
                    </div>
                    <p className="text-success">Energieausweis</p>
                  </div>
                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                      <Check size={15} />
                    </div>
                    <p className="text-success">Betriebskosten Übersicht</p>
                  </div>
                </div>
                <div className="col-12 col-lg-6 mb-6 mb-md-0">
                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                      <Check size={15} />
                    </div>
                    <p className="text-success">
                      Objekt-Details
                      <br />
                      (z.B. Baujahr, letzte Renovierung, Bausubstanz)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <CTA className="py-6 py-md-8" />
        </div>
      </section>

      <div className="position-relative mt-n12">
        <div className="shape shape-bottom shape-fluid-x svg-shim text-gray-200">
          <Curve3 />
        </div>
      </div>

      <section className="pt-11 bg-gray-200" style={{ overflowX: "hidden" }}>
        <div className="container">
          <div className="scroll-beacon" id="tipps-maklersuche" />
          <div className="row justify-content-center mb-5">
            <div className="col-12 col-md-10 col-lg-8 text-center">
              <h2 className="fw-bold">3 wichtige Tipps für Ihre Maklersuche</h2>
            </div>
          </div>
          <div className="row align-items-center justify-content-between mb-7">
            <div className="col-12 col-md-6 order-md-2">
              <h3 className="fw-bold">
                Qualifikation, Kompetenz und Reputation?
              </h3>

              <p className="text-muted mb-0">
                Beim Verkauf einer Immobilie geht es in aller Regel um
                beträchtliche Summen. Nicht zuletzt deshalb, sollte ein{" "}
                <b>starkes Vertrauen dem Makler gegenüber</b>, eines Ihrer
                wichtigsten Auswahlkriterien sein. Ein guter Immobilienmakler
                arbeitet transparent und kommuniziert stets offen, sodass Sie
                als Eigentümer immer auf dem aktuellsten Stand der Dinge bleiben
                und über Neuerungen regelmäßig informiert werden. Vergewissern
                Sie sich, einen absoluten Profi an Ihrer Seite zu haben. Sie
                sichern sich ab, indem Sie die{" "}
                <b>Referenzen und Qualifikationen</b> eines Maklers genau unter
                die Lupe nehmen. Dazu gehören:
              </p>
            </div>
            <div className="col-12 order-md-2 mt-8 mb-6 mb-md-0">
              <div className="row text-secondary">
                <div className="col-12 col-md-6 col-lg-4 d-flex">
                  <div className="badge badge-lg badge-rounded-circle bg-primary-desat-soft me-3 align-text-bottom">
                    <Search size={15} />
                  </div>
                  <p className="text">
                    Ein makelloser Internetauftritt (Visitenkarte, Exposés,
                    eigene Website)
                  </p>
                </div>
                <div className="col-12 col-md-6 col-lg-4 d-flex">
                  <div className="badge badge-lg badge-rounded-circle bg-primary-desat-soft me-3 align-text-bottom">
                    <Search size={15} />
                  </div>
                  <p className="text">
                    Abschluss einer Vermögensschaden-Haftpflichtversicherung
                  </p>
                </div>
                <div className="col-12 col-md-6 col-lg-4 d-flex">
                  <div className="badge badge-lg badge-rounded-circle bg-primary-desat-soft me-3 align-text-bottom">
                    <Search size={15} />
                  </div>
                  <p className="text">
                    Maklerschein gemäß{" "}
                    <a
                      href="https://www.gesetze-im-internet.de/gewo/__34c.html"
                      target="_blank"
                    >
                      § 34c der Gewerbeordnung
                    </a>
                  </p>
                </div>
                <div className="col-12 col-md-6 col-lg-4 d-flex">
                  <div className="badge badge-lg badge-rounded-circle bg-primary-desat-soft me-3 align-text-bottom">
                    <Search size={15} />
                  </div>
                  <p className="text">
                    Mitgliedschaft in einem Makler-Berufsverband (z.B.{" "}
                    <a href="https://ivd.net/" target="_blank">
                      IDV
                    </a>
                    )
                  </p>
                </div>
                <div className="col-12 col-md-6 col-lg-4 d-flex">
                  <div className="badge badge-lg badge-rounded-circle bg-primary-desat-soft me-3 align-text-bottom">
                    <Search size={15} />
                  </div>
                  <p className="text">
                    Eine individuelle Beratung ohne Zeitdruck
                  </p>
                </div>
                <div className="col-12 col-md-6 col-lg-4 d-flex">
                  <div className="badge badge-lg badge-rounded-circle bg-primary-desat-soft me-3 align-text-bottom">
                    <Search size={15} />
                  </div>
                  <p className="text">Einwandfreie Kundenbewertungen</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 order-md-1">
              <div
                data-sal="slide-right"
                data-sal-delay="200"
                data-sal-duration="700"
              >
                <Image
                  src="AdobeStock_172382369.jpg"
                  alt="Beratung zu Immobilie von Makler"
                  imgStyle={{ objectFit: "contain" }}
                  className="screenshot img-fluid mw-110 float-end me-md-6 mb-6 mb-md-0 w-100 w-md-110"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*<section className="bg-gray-200">*/}
      {/*  <div className="pb-5 pb-sm-9 pb-lg-7">*/}
      {/*    <div className="scroll-beacon" id="immobilienmakler" />*/}
      {/*    <div style={{ minHeight: "417px" }}>*/}
      {/*      <LeadGeneratorLoadable leadGenerator="kontakt" intuitive />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}

      <section
        className="pb-11 bg-gray-200 border-bottom"
        // style={{ overflow: "hidden" }}
      >
        <div className="container">
          <div className="row align-items-center mb-7">
            <div className="col-12 col-md-6">
              <h3 className="fw-bold">Passender Makler zur Immobilie</h3>
              <p className="text-muted">
                Sehr entscheidend für den erfolgreichen Verkauf Ihrer Immobilie,
                ist zudem ein passendes „Match“.
              </p>
              <p className="text-muted mb-6">
                Beispiel: Ein hochwertiges Luxus-Loft in der Großstadt erfordert
                einen ganz anderen Typ Makler als das Mehr­familienhaus in einer
                Kleinstadt. Nehmen Sie sich daher bei der Maklersuche Zeit, DEN
                Makler zu finden, der Ihre{" "}
                <b>Immobilie am besten repräsentieren</b> kann. Als
                Auswahlkriterium kann hierfür das Erscheinungsbild der
                jeweiligen Exposés hilfreich sein. Und auch am Marken-Image
                lässt sich schnell ablesen, ob ein Makler zu Ihrer Immobilie
                passt oder nicht.
              </p>
            </div>
            <div className="col-12 col-md-6">
              <div
                data-sal="slide-left"
                data-sal-delay="200"
                data-sal-duration="700"
              >
                <Image
                  src="AdobeStock_102603660.jpg"
                  imgStyle={{ objectFit: "contain" }}
                  className="screenshot img-fluid float-start ms-md-6 w-100 w-md-110"
                  alt="Übergabe Haus-Schlüssel an Makler - Vermarktung"
                />
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-12">
              <h3 className="fw-bold">Richtige Vertragsart finden</h3>
              <p className="text-muted mb-7 mb-md-9">
                Ein Makler wird erst dann tätig, wenn ihm ein schriftlicher
                Auftrag von Ihnen als Eigentümer vorliegt. Dafür kommen{" "}
                <b>verschiedene Vertragsarten</b> in Frage, die wir Ihnen in der
                folgenden Tabelle vorstellen möchten:
              </p>

              <div
                id="vertragsarten"
                className="card-group card-border card-border-lg border-primary shadow-light-lg mb-8 mb-md-11"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col"> </th>
                            <th scope="col">Eigenschaften</th>
                            <th scope="col">Vorteile</th>
                            <th scope="col">Nachteile</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">Einfacher Maklervertrag</th>
                            <td>
                              Mehrere Makler können gleichzeitig beauftragt
                              werden. Auch Sie als Eigentümer können tätig
                              werden.
                            </td>
                            <td>
                              <Plus
                                size={15}
                                className="align-baseline text-secondary"
                              />{" "}
                              Jederzeit kündbar.
                            </td>
                            <td>
                              <Minus
                                size={15}
                                className="align-baseline text-secondary"
                              />{" "}
                              Motivation der Makler ist niedrig.
                              <br />
                              <Minus
                                size={15}
                                className="align-baseline text-secondary"
                              />{" "}
                              Viele verschiedene Inserate Ihrer Immobilie
                              schaden womöglich dem Image.
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Makler-alleinauftrag</th>
                            <td>
                              Nur ein Makler kann beauftragt werden. Auch Sie
                              als Eigentümer können tätig werden.
                            </td>
                            <td>
                              <Plus
                                size={15}
                                className="align-baseline text-secondary"
                              />{" "}
                              Bei eigenem Erfolg fällt keine Maklerprovision an.
                              <br />
                              <Plus
                                size={15}
                                className="align-baseline text-secondary"
                              />{" "}
                              Motivation des Maklers ist hoch (v.a. bei
                              Befristung des Vertrags).
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <th scope="row">Qualifizierter Alleinauftrag</th>
                            <td>
                              Nur ein Makler kann beauftragt werden. Sie als
                              Eigentümer dürfen NICHT tätig werden.
                            </td>
                            <td>
                              <Plus
                                size={15}
                                className="align-baseline text-secondary"
                              />{" "}
                              Festlegung von spezifischen Vermarktungsmaßnahmen
                              (Bei Nichteinhalten, Recht auf Kündigung)
                              <br />
                              <Plus
                                size={15}
                                className="align-baseline text-secondary"
                              />{" "}
                              Reservierung für Kunden möglich.
                            </td>
                            <td>
                              <Minus
                                size={15}
                                className="align-baseline text-secondary"
                              />{" "}
                              Makler hat Anspruch auf Schadensersatz, wenn Sie
                              als Eigentümer tätig werden.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-center g-0">
                <div className="col-12 col-md-6">
                  <div
                    className="card rounded-3 shadow-lg mb-6 mb-md-0"
                    style={{ zIndex: 1 }}
                  >
                    <Image
                      src="Fotolia_75513646_XS.jpg"
                      alt="Vereinbarung zwischen Makler"
                      className="card-img-top"
                      // style={{ height: "220px" }}
                    />

                    <p
                      className="text-center mb-8 position-absolute"
                      style={{
                        top: "10%",
                        left: "50%",
                        transform: "translate(-50%, 0%)",
                      }}
                    >
                      <span className="badge rounded-pill bg-primary">
                        <span className="h6 fw-bold text-uppercase">
                          Extra Tipp
                        </span>
                      </span>
                    </p>

                    <div className="position-relative">
                      <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
                        <Curve1 />
                      </div>
                    </div>

                    <div className="card-body py-6 py-md-8">
                      <div className="row justify-content-center">
                        <div className="col-12 col-xl-10">
                          <p className="lead text-center text-muted mb-0">
                            Zudem sollten Sie darauf achten, dass ein Vertrag
                            bestimmte <b>Inhalte und Vereinbarungen</b> enthält,
                            mit denen Sie sich als Eigentümer auf der sicheren
                            Seite wiegen können. Dazu gehören:
                          </p>
                        </div>
                      </div>
                    </div>

                    <a
                      href="#makler-finden"
                      onClick={smoothScroll}
                      className="card-btn btn btn-block btn-lg btn-secondary"
                    >
                      Makler jetzt finden
                    </a>
                  </div>
                </div>
                <div className="col-12 col-md-6 ms-md-n3">
                  <div className="card rounded-3 shadow-lg">
                    <div className="card-body py-6 py-md-8">
                      <div className="row justify-content-center text-secondary">
                        <div className="col-12 col-xl-9">
                          <div className="d-flex">
                            <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                              <Check size={15} />
                            </div>
                            <p>Genaue Angaben zu Ihrer Immobilie</p>
                          </div>
                          <div className="d-flex">
                            <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                              <Check size={15} />
                            </div>
                            <p>Ihre Daten als Eigentümer</p>
                          </div>
                          <div className="d-flex">
                            <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                              <Check size={15} />
                            </div>
                            <p>Sämtliche Daten des Maklers</p>
                          </div>
                          <div className="d-flex">
                            <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                              <Check size={15} />
                            </div>
                            <p>Vertragsart und Vermarktungsmaßnahmen</p>
                          </div>
                          <div className="d-flex">
                            <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                              <Check size={15} />
                            </div>
                            <p>Provisionshöhe</p>
                          </div>
                          <div className="d-flex">
                            <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                              <Check size={15} />
                            </div>
                            <p>Etwaige Befristung des Vertrags</p>
                          </div>
                          <div className="d-flex">
                            <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                              <Check size={15} />
                            </div>
                            <p>Kündigungsfristen</p>
                          </div>
                          <div className="d-flex">
                            <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                              <Check size={15} />
                            </div>
                            <p>Provision/Aufwandsentschädigung</p>
                          </div>
                          <div className="d-flex">
                            <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                              <Check size={15} />
                            </div>
                            <p>Pflichten beider Parteien</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="py-8 py-md-11"
        style={{
          background:
            "linear-gradient(0deg, rgba(241,244,248,1) 0%, rgba(255,255,255,1) 100%)",
        }}
      >
        <div className="container">
          <div className="scroll-beacon" id="makler-finden-checkliste" />
          <div className="row justify-content-center mb-5">
            <div className="col-12 col-md-10 col-lg-8 text-center">
              <h2 className="fw-bold">
                Makler finden – Schritt für Schritt Checkliste
              </h2>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="d-flex">
                <div className="badge badge-lg badge-rounded-circle bg-primary-desat-soft mt-1">
                  <span>1</span>
                </div>
                <div className="ms-5 mt-1">
                  <h3>
                    Makler Ja oder Nein? Bedenken Sie dabei, ob folgende Punkte
                    bei Ihnen gegeben sind:
                  </h3>
                  <p className="text-secondary mb-6">
                    <ChevronRight size={15} className="align-middle" />{" "}
                    Ausreichende zeitliche Ressourcen
                    <br />
                    <ChevronRight size={15} className="align-middle" />{" "}
                    Kenntnisse über Markt und Rechtslage
                    <br />
                    <ChevronRight size={15} className="align-middle" />{" "}
                    Potenzielle Kundenkontakte
                    <br />
                    <ChevronRight size={15} className="align-middle" />{" "}
                    Zuverlässige Immobilienbewertung
                    <br />
                    <ChevronRight size={15} className="align-middle" />{" "}
                    Souveräne Preisverhandlungen
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex">
                <div className="badge badge-lg badge-rounded-circle bg-primary-desat-soft mt-1">
                  <span>2</span>
                </div>
                <div className="ms-5 mt-1">
                  <h3>Kostenlose Makler Empfehlungen erhalten:</h3>
                  <p className="text-secondary mb-6">
                    <ChevronRight size={15} className="align-middle" />{" "}
                    <u>
                      <a
                        href="#makler-finden"
                        onClick={smoothScroll}
                        className="text-muted"
                      >
                        Makler finden
                      </a>
                    </u>
                    <br />
                    <ChevronRight size={15} className="align-middle" /> Suche
                    eingrenzen
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex">
                <div className="badge badge-lg badge-rounded-circle bg-primary-desat-soft mt-1">
                  <span>3</span>
                </div>
                <div className="ms-5 mt-1">
                  <h3>Makler Auswahl anhand der Kriterien:</h3>
                  <p className="text-secondary mb-6">
                    <ChevronRight size={15} className="align-middle" /> Präsenz
                    in Ihrer Region
                    <br />
                    <ChevronRight size={15} className="align-middle" />{" "}
                    Kundenbewertungen
                    <br />
                    <ChevronRight size={15} className="align-middle" /> Exposés
                    <br />
                    <ChevronRight size={15} className="align-middle" />{" "}
                    Visitenkarte und Referenzen
                    <br />
                    <ChevronRight size={15} className="align-middle" />{" "}
                    Transparente und schnelle Kommunikation
                    <br />
                    <ChevronRight size={15} className="align-middle" />{" "}
                    Professionalität
                    <br />
                    <ChevronRight size={15} className="align-middle" /> Höhe der{" "}
                    <u>
                      <Link
                        to="/de/immobilienbewertung/"
                        className="text-muted"
                      >
                        Immobilienbewertung
                      </Link>
                    </u>
                    <br />
                    <ChevronRight size={15} className="align-middle" />{" "}
                    Menschliche Ebene
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex">
                <div className="badge badge-lg badge-rounded-circle bg-primary-desat-soft mt-1">
                  <span>4</span>
                </div>
                <div className="ms-5 mt-1">
                  <h3>
                    Maklerprovision verhandeln, anhand folgender Kriterien:
                  </h3>
                  <p className="text-secondary mb-6">
                    <ChevronRight size={15} className="align-middle" /> Aktuelle
                    Marktlage
                    <br />
                    <ChevronRight size={15} className="align-middle" /> Preis
                    Ihrer Immobilie
                    <br />
                    <ChevronRight size={15} className="align-middle" />{" "}
                    Marktübliche Konditionen
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex">
                <div className="badge badge-lg badge-rounded-circle bg-primary-desat-soft mt-1">
                  <span>5</span>
                </div>
                <div className="ms-5 mt-1">
                  <h3>
                    Vertrag unterschreiben. Er sollte folgenden Punkte
                    berücksichtigen:
                  </h3>
                  <p className="text-secondary mb-6">
                    <ChevronRight size={15} className="align-middle" />{" "}
                    Geforderter Verkaufspreis
                    <br />
                    <ChevronRight size={15} className="align-middle" />{" "}
                    Provisionshöhe
                    <br />
                    <ChevronRight size={15} className="align-middle" />{" "}
                    Ablauffrist
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/*<SectionMaklerCheckliste/>*/}
        </div>
      </section>

      <section className="bg-gray-200">
        <div className="container-fluid">
          <div
            className="card card-bleed shadow-light-lg mx-md-auto"
            style={{ minHeight: "495px", maxWidth: "767px" }}
          >
            <div className="scroll-beacon" id="makler-finden" />
            <LeadGeneratorLoadable
              leadGenerator="makler"
              setPath={fixedPathname}
              accordionState={accordion}
            />
          </div>
        </div>
      </section>

      <div className="position-relative mt-n15">
        <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
          <Curve1 />
        </div>
      </div>

      <section className="py-12 bg-white">{/*Platzhalter Curve*/}</section>
    </>
  )
}

export default MaklerFinden
